import React from "react";
import { Link } from "react-router-dom";

//Images
import minDeposit from "./../../assets/images/icons/mindeposit.svg";
import maxDeposit from "./../../assets/images/icons/maximumdeposit1.svg";
import dailyBonus from "./../../assets/images/icons/dailybonus.svg";
import holdBonus from "./../../assets/images/icons/holdbonus.svg";
import Icon13 from "./../../assets/images/icons/icon13.svg";
import pic1 from "./../../assets/images/about/pic1.jpg";
import support1 from "./../../assets/images/icons/support1.png";

const contractLink =
  "https://polygonscan.com/address/0xbB8244425Cd66bba6db5838996827e1720E8eC6f";

const cardData = [
  {
    image: minDeposit,
    title: "MINIMUM DEPOSIT",
    description:
      "Starting your investment journey requires only a minimum of $10. Even with a small initial investment of $10, you can embark on the path to financial growth.",
  },
  {
    image: maxDeposit,
    title: "MAXIMUM DEPOSIT",
    description:
      "Should you desire, the opportunity is available for you to invest up to a maximum of $1,000,000",
  },
  {
    image: dailyBonus,
    title: "Daily Earnings",
    description:
      "You begin earning 0.5% daily on your deposited Matic funds in the Matic Fund smart contract.",
  },
  {
    image: holdBonus,
    title: "Hold Bonus",
    description:
      "You can increase your daily earnings based on your last withdrawal date. If you don't withdraw for 24 hours, your daily Matic earnings increase by 0.05% until reaching a maximum of 1%. Afterward, it remains at 1% for each subsequent day.",
  },
  {
    image: Icon13,
    title: "Matic Fund Decentralized Trading Strategy",
    description:
      "When the value of Matic rises, it earns twice the amount in dollars. Conversely, when the Matic value declines, it earns twice the amount in Matic.",
  },
];

function OneStop() {
  return (
    <>
      {cardData.map((item, ind) => (
        <div className="col-xl-4 col-md-6 m-b60" key={ind}>
          <div className="icon-bx-wraper style-3 text-center">
            <div className="icon-media">
              <img src={item.image} alt="" />
            </div>
            <div className="icon-content">
              <h4 className="title">{item.title}</h4>
              <p className="m-b0">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
      <div className="col-xl-4 col-md-6 m-b60">
        <div
          className="icon-bx-wraper style-4"
          style={{ backgroundImage: "url(" + pic1 + ")" }}>
          <div className="inner-content">
            <div className="icon-media m-b30">
              <img src={support1} alt="" />
            </div>
            <div className="icon-content">
              <Link to={contractLink} className="btn btn-primary">
                Smart Contract
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OneStop;
