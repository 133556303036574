import React from "react";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
const { getCode, getName } = require("country-list");

function RecentNews({ news }) {
  return (
    <>
      {news.map(
        (data, ind) =>
          ind < 3 && (
            <div className="dz-card style-1 blog-half m-b30" key={ind}>
              <div className="dz-media">
                <Link to={data.link} rel="nofollow">
                  <img src={data.image_url} alt={data.keywords} />
                </Link>
                <ul className="dz-badge-list">
                  <li>
                    <Link to={data.link} className="dz-badge">
                      {data.pubDate}
                    </Link>
                  </li>
                </ul>
                <Link to={data.link} className="btn btn-secondary">
                  Read More
                </Link>
              </div>
              <div className="dz-info">
                <div className="dz-meta">
                  <ul>
                    <li className="post-author">
                      <Link to={"#"}>
                        <ReactCountryFlag
                          className="emojiFlag me-2"
                          countryCode={getCode(data.country)}
                          style={{
                            fontSize: "1.5em",
                            lineHeight: "1.5em",
                          }}
                        />
                        <span>{data.creator}</span>
                      </Link>
                    </li>
                    <li className="post-date">{data.pubDate}</li>
                  </ul>
                </div>
                <h4 className="dz-title">
                  <Link to={data.link}>
                    {data.title.substring(0, 50) + "..."}
                  </Link>
                </h4>
                <p className="m-b0">
                  {data.description.substring(0, 75) + "..."}
                </p>
              </div>
            </div>
          )
      )}
    </>
  );
}
export default RecentNews;
