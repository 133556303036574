import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "../features/wallets";
import themeReducer from "../features/theme";
import contractReducer from "../features/contract";

export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    theme: themeReducer,
    contract: contractReducer,
  },
});
