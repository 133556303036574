import axios from "axios";

export const toEther = (val) => {
  return parseFloat(parseInt(val._hex, 16) / 1e18).toFixed(2);
};

export const toTimeStamp = (val) => {
    return parseInt(val._hex,16);
};

export const parseUserDepositStats = (userDepositStats) => {
  if (userDepositStats) {
    let totalDeposits = toEther(userDepositStats[0]);
    let totalUsdDeposits = toEther(userDepositStats[1]);
    let activeDeposits = toEther(userDepositStats[2]);
    let activeUsdDeposits = toEther(userDepositStats[3]);
    let countOfTotalDeposits = toTimeStamp(userDepositStats[4]);
    let getUserDeposits = userDepositStats[5];
    let allDeposits = [];
    let i = 0;
    getUserDeposits.map((deposit) => {
      if (deposit.active) i++;
      allDeposits.push({
        amount: toEther(deposit.amount),
        start: toTimeStamp(deposit.start),
        usd_amount: toEther(deposit.usd_amount),
        withdrawn: toEther(deposit.withdrawn),
        usd_withdrawn: toEther(deposit.usd_withdrawn),
        active: deposit.active,
      });
    });
    return {
      totalDeposits,
      totalUsdDeposits,
      countOfActiveDeposit: i,
      activeDeposits,
      activeUsdDeposits,
      countOfTotalDeposits,
      allDeposits,
    };
  }
};

export const parsedSiteStats = (siteStats) => {
  return {
    totalInvest: toEther(siteStats[0]),
    totalInvestCount: toTimeStamp(siteStats[1]),
    balance: toEther(siteStats[2]),
    percentage: toEther(siteStats[3]),
    tradeProfit:
      toEther(siteStats[4]) < toEther(siteStats[0]) * 0.025
        ? toEther(siteStats[0]) * 0.025
        : toEther(siteStats[4]),
  };
};

export const parsedReferralStats = (referralStats) => {
  return {
    referrer: referralStats[0],
    totalRefBonus: toEther(referralStats[1]),
    totalUsdRefBonus: toEther(referralStats[2]),
    firstLineCount: toTimeStamp(referralStats[3]),
    depth: referralStats[4],
    totalTeamCount: toTimeStamp(referralStats[5]),
    totalTeamInvest: toEther(referralStats[6]),
  };
};

export const parseUserWithdrawStats = (withdrawStats) => {
  let totalWithdrawn = toEther(withdrawStats[0]);
  let totalUsdWithdrawn = toEther(withdrawStats[1]);
  return { totalWithdrawn, totalUsdWithdrawn };
};

export const parseAccountStats = (accStats) => {
  let percentRate = toTimeStamp(accStats[0]);
  let available = parseInt(accStats[1]._hex) / 1e18
  //console.log("accStats",accStats[1])
  return { percentRate, available };
};

export const getNews = async () => {
  let news = await axios
    .get("https://news.maticfund.com/news")
    .then((result) => {
      if (result.data.length > 0) {
        let last = result.data.filter((data) => {
          return data.article_id !== null;
        });
        return last;
      }
    })
    .catch((err) => []);
  return news;
};
export const hndPrsFl = (val) => parseFloat(val).toFixed(2);
