import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "./../layouts/ScrollToTop";
import Header from "./../layouts/Header";
import Footer from "./../layouts/Footer";
import Home from "./Home";
import Ref from "./Ref";
import Pricing from "./Pricing";
import BlogList from "./BlogList";
import BlogGrid from "./BlogGrid";
import BlogDetails from "./BlogDetails";
import ContactUs from "./ContactUs";
import ReactGA from 'react-ga';
const TRACKING_ID = "G-KC37J828L5";
ReactGA.initialize(TRACKING_ID);

function Index() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename="/">
      <div className="page-wraper">
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/ref" element={<Ref />} />
          <Route path="/pricing" exact element={<Pricing />} />
        </Routes>
        <Footer />
        <ScrollToTop />
      </div>
    </BrowserRouter>
  );
}
export default Index;
