import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { isAddress } from "web3-validator";
import { useContract, useContractRead } from "@thirdweb-dev/react";
import { Image } from "react-bootstrap";
import loaderGif from "./../assets/images/loader.gif";

function Ref() {
  const router = useNavigate();

  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const [loading, setLoading] = useState(false);
  const address = new URLSearchParams(window.location.search).get("a");
  const cookies = new Cookies();
  const { data: isActive, isLoading: isActiveLoading } = useContractRead(
    contract,
    "isActive",
    [address]
  );

  useEffect(() => {
    setLoading(true);
    if (!isActiveLoading) {
      if (!address) address = process.env.REACT_APP_DEFAULT_ADDRESS;
      if (isActive && isAddress(address)) {
        cookies.set("refAddress", address, {
          path: "/",
          expires: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000),
        });
       /* if (
          cookies.get("refAddress") === undefined ||
          cookies.get("refAddress") == process.env.REACT_APP_DEFAULT_ADDRESS ||
          cookies.get("refAddress") == ""
        ) {
          
        }*/
      } else {
        cookies.set("refAddress", process.env.REACT_APP_DEFAULT_ADDRESS, {
          path: "/",
          expires: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000),
        });
      }
      //console.log(address, cookies.get("refAddress"));
      router("/");
      setLoading(false);
    }
  }, [isActiveLoading]);

  return (
    <>
      {loading && (
        <div
          style={{
            zIndex: 99999,
            height: "100vh",
            width: "100%",
            textAlign: "center",
            position: "fixed",
            display: "block",
            backgroundColor: "rgb(239 243 254)",
          }}>
          <Image src={loaderGif} style={{ marginTop: "20%" }} />
        </div>
      )}
    </>
  );
}
export default Ref;
