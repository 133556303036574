import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  isLogin: false,
  totalDeposits: 0,
  totalUsdDeposits: 0,
  activeDeposits: 0,
  activeUsdDeposits: 0,
  countOfTotalDeposits: 0,
  countOfActiveDeposit: 0,
  available: 0,
  oldAvailable: {oldAvailable: 0, available: 0},
  percentRate: 0,
  totalWithdrawn: 0,
  totalUsdWithdrawn: 0,
  referrer: "",
  holdRewardPercent: 0,
  deposits: [],
  filteredDeposits: [],
  firstLineCount: 0,
  yourActiveInvest: 0.0,
  totalTeamCount: 0.0,
  totalTeamInvest: 0.0,
  totalRefBonus: 0.0,
  totalUsdRefBonus: 0.0,
  missedAmount: 0.0,
  allDeposits: [],
  depth: [],
  depthRights: 0,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    login: (state, action) => {
      state.address = action.payload;
      state.isLogin = true;
    },
    logout: (state) => {
      state.address = "";
      state.isLogin = false;
    },
    changeToDefault: (state) => {
      state = initialState;
    },
    addDepositStats: (state, action) => {
      state.totalDeposits = action.payload.totalDeposits;
      state.totalUsdDeposits = action.payload.totalUsdDeposits;
      state.activeDeposits = action.payload.activeDeposits;
      state.activeUsdDeposits = action.payload.activeUsdDeposits;
      state.countOfActiveDeposit = action.payload.countOfActiveDeposit;
      state.countOfTotalDeposits = action.payload.countOfTotalDeposits;
      state.allDeposits = action.payload.allDeposits;
      state.filteredDeposits = action.payload.allDeposits;
    },
    filterDepositStats: (state, action) => {
      switch (action.payload.filterState.state) {
        case "all":
          state.filteredDeposits = state.allDeposits;
          break;
        case "active":
          state.filteredDeposits = state.allDeposits.filter((deposit) => {
            return deposit.active;
          });
          break;
        case "passive":
          state.filteredDeposits = state.allDeposits.filter((deposit) => {
            return !deposit.active;
          });
          break;
      }
    },
    sortFilteredDeposits: (state, action) => {
      let sortedArray = [...state.filteredDeposits];
      sortedArray.sort((a, b) => {
        return action.payload
          ? new Date(b.start) - new Date(a.start)
          : new Date(a.start) - new Date(b.start);
      });
      state.filteredDeposits = sortedArray;
    },
    addWithdrawnStats: (state, action) => {
      state.totalWithdrawn = action.payload.totalWithdrawn;
      state.totalUsdWithdrawn = action.payload.totalUsdWithdrawn;
    },
    addAccountStats: (state, action) => {
      state.oldAvailable.oldAvailable = state.available;
      state.oldAvailable.available = action.payload.available;
      state.available = action.payload.available;
      state.percentRate = action.payload.percentRate;
    },
    addReferralStats: (state, action) => {
      state.referrer = action.payload.referrer;
      state.totalRefBonus = action.payload.totalRefBonus;
      state.totalUsdRefBonus = action.payload.totalUsdRefBonus;
      state.firstLineCount = action.payload.firstLineCount;
      state.depth = action.payload.depth;
      state.totalTeamCount = action.payload.totalTeamCount;
      state.totalTeamInvest = action.payload.totalTeamInvest;
    },
    setUserDepth: (state, action) => {
      state.depthRights = action.payload;
    },
    setUserMissedAmont: (state, action) => {
      state.missedAmount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logout,
  changeToDefault,
  addDepositStats,
  addWithdrawnStats,
  addAccountStats,
  addReferralStats,
  setUserDepth,
  setUserMissedAmont,
  filterDepositStats,
  sortFilteredDeposits,
} = walletSlice.actions;

export default walletSlice.reducer;
