import React, { useState, useEffect } from "react";
import {
  ConnectWallet,
  useAddress,
  ChainId,
  useNetworkMismatch,
  useSwitchChain,
  useConnectionStatus,
  useChainId,
} from "@thirdweb-dev/react";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../../features/wallets";

function ConnectButton() {
  const address = useAddress();
  const status = useConnectionStatus();
  const switchChain = useSwitchChain();
  const isMismatched = useNetworkMismatch();
  const dispatch = useDispatch();
  const loginAddress = useSelector((state) => state.wallet.address);

  /** Stateler */
  const theme = useSelector((state) => state.theme.mode);
  const [switchNetwork, setSwitchNetwork] = useState(false);
  const chainId = useChainId();

  useEffect(() => {
    if (status === "connected") {
      dispatch(login(address));

      setSwitchNetwork(isMismatched);
      if (isMismatched) {
        toast.error(
          "You need change your network. Please click Switch Network button."
        );
      }
    } else if (status === "disconnected") {
      dispatch(logout());
    }
  }, [address, status, isMismatched]);

  return (
    <>
      <>
        <Toaster />
      </>
      {switchNetwork ? (
        <button
          onClick={() => {
            switchChain(ChainId.Polygon);
          }}
          className="btn btn-danger"
          type="button">
          Switch Network
        </button>
      ) : (
        <ConnectWallet theme={theme} modalSize={"wide"} />
      )}
    </>
  );
}

export default ConnectButton;
