import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  mode: "dark",
  news: [],
  isLoading: false,
};

export const fetchNews = createAsyncThunk("theme/fetchNews", async () => {
  const res = await axios("https://news.maticfund.com/news")
    .then((res) => {
      if (res.data.length > 0) {
        return res.data.filter((data) => {
          return data.article_id !== null;
        });
      }
    })
    .catch((err) => []);
  return res;
});

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeToLight: (state) => {
      state.mode = "light";
    },
    changeToDark: (state) => {
      state.mode = "dark";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.news = action.payload;
    });
    builder.addCase(fetchNews.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { changeToLight, changeToDark, getNews } = themeSlice.actions;

export default themeSlice.reducer;
